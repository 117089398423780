import React from 'react';
import index from './views/index.js';

function App() {
  return (
    <div className="App">
    {index}

    </div>
  );
}

export default App;
