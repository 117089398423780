import React from 'react';
import ReactDOM from 'react-dom';

import textInfo from './home_txt.js'


class Home extends React.Component {
  render() {
    return(
        <div>
          {textInfo}
        </div>
      )
  }

}

function home() {
  ReactDOM.render(<Home />, document.getElementById('workPlace'));
}

export default home
