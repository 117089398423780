import React from 'react';

const textInfo = (
  <div>
    <hr className="my-4"/>
    <em className="text-info">[1729, 4104, 13832, 20683, 32832, 39312, 40033, 46683, 64232] </em>
    <br/>
    <br/>
  </div>
)

export default textInfo
