import React from 'react';
import ReactDOM from 'react-dom';
import footer from './footer'

const textInfo = (
  <div>
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">404</h1>
  </div>
  <div className="jumbotron">
    <pre>{`
    ,+++77777++=:,                    +=                      ,,++=7++=,,
   7~?7   +7I77 :,I777  I          77 7+77 7:        ,?777777??~,=+=~I7?,=77 I
=7I7I~7  ,77: ++:~+777777 7     +77=7 =7I7     ,I777= 77,:~7 +?7, ~7   ~ 777?
77+7I 777~,,=7~  ,::7=7: 7 77   77: 7 7 +77,7 I777~+777I=   =:,77,77  77 7,777,
 = 7  ?7 , 7~,~  + 77 ?: :?777 +~77 77? I7777I7I7 777+77   =:, ?7   +7 777?
     77 ~I == ~77=77777~: I,+77?  7  7:?7? ?7 7 7 77 ~I   7I,,?7 I77~
      I 7=77~+77+?=:I+~77?     , I 7? 77 7   777~ +7 I+?7  +7~?777,77I
        =77 77= +7 7777         ,7 7?7:,??7     +7    7   77??+ 7777,
            =I, I 7+:77?         +7I7?7777 :             :7 7
               7I7I?77 ~         +7:77,     ~         +7,::7   7
              ,7~77?7? ?:         7+:77           77 :7777=
               ?77 +I7+,7         7~  7,+7  ,?       ?7?~?777:
                  I777=7777 ~     77 :  77 =7+,    I77  777
                    +      ~?     , + 7    ,, ~I,  = ? ,
                                   77:I+
                                   ,7
                                    :777
                                       :

 `}
    </pre>
    <a href="https://pastebin.com/t6B6fhcv" target="_blank">16[485] - Welcome</a>
  </div>
  {footer}
  </div>
)


class Error extends React.Component {
  render() {
    return(
        <div>
          {textInfo}
        </div>
      )
  }

}

function error() {
  ReactDOM.render(<Error />, document.getElementById('workPlace'));
}

export default error
